import React, { useEffect, useState, useCallback } from 'react'
import { motion } from 'framer-motion'
import { Button, Input } from '@nextui-org/react'
import TextLoop from 'react-text-loop'
import { useRouter } from 'next/router'
import Faq from '@/components/Faq'
import ThemeSwitcher from '@/components/ThemeSwitcher'
import Footer from '@/components/Footer'
import ChatIntro from '@/components/HomePage/ChatIntro'
import AIImages from '@/components/HomePage/ImageList'
import { getToken } from '@/utils/request'
import { useModel } from '@/plugin-model/useModel'
import { getSelectImages } from '@/services/ai_images'
import RandomPrompt from '@/components/Image/RandomPrompt'

let requestingPage = 0

const Home = () => {
  const router = useRouter()
  const { view, viewSet } = useModel('view')
  const { invite } = router.query
  const [hasMore, setHasMore] = useState(true)
  const [prompt, setPrompt] = useState('')
  const [images, setImages] = useState(undefined)
  const page = 1
  const pageSize = 20
  const user_id = view.user_id

  const fetchImages = useCallback(async () => {
    try {
      if (requestingPage >= page) {
        return
      }
      requestingPage = page
      const res = await getSelectImages('all', page, pageSize, user_id)
      setHasMore(false)
      setImages(res.data)
    } catch (error) {
      console.error(error)
    }
  }, [page, user_id])

  const onChange = useCallback(() => {
    setHasMore(false)
    requestingPage = 0
    fetchImages()
  }, [fetchImages])

  useEffect(() => {
    if (!getToken() && invite) {
      viewSet('loginDialogOpen', true)
    }
  }, [invite, viewSet])

  // useEffect(() => {
  //   if (code) {
  //     post('v1/user/douyin_login_notify', { code, state, scopes }).then((res) => {
  //       if (res.code === 200) {
  //         setToken(res.data)
  //         mutate()
  //         viewSet('user_id', res.user.id)
  //         localStorage.setItem('user_id', res.user.id)
  //       }
  //     })
  //   }
  // }, [code, mutate, scopes, state, viewSet])

  return (
    <div>
      <div className="relative h-[75vh]">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
          }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="absolute bottom-0 h-full w-full bg-[url('/home/index-bg.png')] bg-cover bg-center"
        />
        <div className="container m-auto flex h-full flex-col items-center justify-center gap-16 px-4 lg:items-start">
          <div className="text-4xl font-bold lg:text-5xl">Ground AI</div>
          <div className="text-4xl font-bold lg:text-5xl">
            <TextLoop interval={5000}>
              <span>
                <span className="text-green-600">创意</span>落地，绘就<span className="text-orange-600">未来</span>
              </span>
              <span>
                <span className="text-orange-600">智绘</span>无限，<span className="text-green-600">创意</span>无边
              </span>
              <span>
                绽放<span className="text-green-600">创意</span>，闪耀<span className="text-orange-600">明天</span>
              </span>
            </TextLoop>
          </div>
          <Input
            className="w-full max-w-[500px]"
            placeholder="输入AI关键词，探索无限可能"
            size="lg"
            value={prompt}
            classNames={{
              mainWrapper: 'h-full justify-center',
              input: 'text-small',
              inputWrapper: 'h-full font-normal text-default-500 bg-default-400/20 dark:bg-default-500/20',
            }}
            onChange={(e) => setPrompt(e.target.value)}
            startContent={<RandomPrompt onChange={setPrompt} />}
            endContent={
              <Button color="primary" className="bg-gradient-to-r from-primary to-red-600" onClick={() => router.push(`/creation?prompt=${prompt}`)} size="sm">
                立即创作
              </Button>
            }
          />
        </div>
      </div>
      <div className="m-auto w-[1440px] max-w-full p-4">
        <ChatIntro />
      </div>
      <div className="m-auto w-[1440px] max-w-full p-4">
        <div className="mb-4 mt-12 text-center text-3xl lg:text-4xl">AI智能绘画</div>
        <div className="text-fill-transparent m-auto mb-12 bg-gradient-to-r from-sky-500 to-primary bg-clip-text text-center">
          能够生成艺术作品、转换艺术风格、处理图像，并创作虚拟场景，是您创作的最佳助手。
        </div>
        <AIImages images={images} onChange={onChange} hasMore={hasMore} setImages={setImages} />
        <div className="mt-12 text-center">
          <Button color="primary" variant="bordered" onClick={() => router.push('/gallery')}>
            查看更多画作
          </Button>
        </div>
      </div>
      <div className="m-auto w-[1440px] max-w-full p-4">
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
          }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="m-auto w-[960px] max-w-full px-2"
        >
          <div className="mb-4 mt-12 text-center text-3xl lg:text-4xl">常见问题</div>
          <Faq />
        </motion.div>
      </div>
      <Footer />
      <div className="fixed bottom-4 right-4 z-30 flex flex-col items-center justify-center gap-2">
        <ThemeSwitcher />
      </div>
    </div>
  )
}

export default Home
