import React, { useState } from 'react'
import { Tooltip } from '@nextui-org/react'
import Button from '@/components/Button'
import IconFont from '../IconFonts'
import { getToken } from '@/utils/request'
import { usePrompt } from '@/swr/useImage'

const RandomPrompt = ({ setValue, onChange }) => {
  const { mutate } = usePrompt()
  const [loading, setLoading] = useState(false)
  return (
    <Tooltip content={getToken() ? 'AI预设提示词' : '请登录后使用AI预设提示词'} showArrow size="sm">
      <div>
        <Button
          isIconOnly
          size="sm"
          isDisabled={!getToken()}
          variant="light"
          isLoading={loading}
          onClick={() =>
            mutate().then((res) => {
              setLoading(true)
              setTimeout(() => {
                if (setValue) {
                  setValue('text_prompts', res)
                }
                if (onChange) {
                  onChange(res)
                }
                setLoading(false)
              }, 1000)
            })
          }
        >
          <IconFont icon="icon-random-prompt" />
        </Button>
      </div>
    </Tooltip>
  )
}

export default RandomPrompt
